<template>
  <div class="wrapper">
    <div class="story-worlds w-100">
      <div class="top-bar d-flex align-items-center px-2">
        <div class="title">{{ sectionTitle }}</div>
      </div>
      <div class="d-flex story-world-list h-100 position-relative">
        <div class="d-flex pb-5 px-0 card-container flex-wrap">
          <ion-card
            v-for="(world, index) in storyWorlds"
            :key="index"
            class="story-world-card ml-0 mt-3 mb-0 mr-3"
            @click="openCard(world)"
          >
            <div class="clickable-item">
              <router-link :to="{ name: 'world-details', params: { slug: world.slug } }">
                <div class="h-100">
                  <div class="d-flex justify-content-center">
                    <img loading="lazy" v-image class="img" :src="resizeUpload(world.cover_image, '250x250')" />
                  </div>

                  <div class="name d-flex justify-content-center mt-2 text-center clickable-item text-black">
                    {{ world.name }}
                  </div>
                </div>
              </router-link>
              <div class="d-flex justify-content-center p-2 align-items-center">
                <Reaction
                  type="world"
                  :reactions="world.reaction_counts"
                  :user-reaction="world.user_reaction"
                  @click.stop
                  @changed="(reaction) => reactionChanged(reaction.key, world, reaction.isInstant)"
                >
                  <ion-button color="transparent" class="inline-button icon-button clickable d-file">
                    <div class="d-flex align-items-center justify-content-center">
                      <i
                        class="text-primary mr-1 ti-thumb-up"
                        :class="{
                          'text-secondary': !!world.user_reaction && world.user_reaction.reaction,
                        }"
                      />
                      <span
                        class="reaction-count"
                        :class="[
                          !!world.user_reaction && world.user_reaction.reaction ? 'text-secondary' : 'text-primary',
                        ]"
                        >{{ world.reaction_counts.total_count || 0 }}</span
                      >
                    </div>
                  </ion-button>
                </Reaction>

                <ion-button color="transparent" class="inline-button icon-button ml-2">
                  <div class="d-flex align-items-center justify-content-center">
                    <ion-icon class="text-primary mr-1 user-icon" :icon="chatboxEllipsesOutline" />
                    <span color=" #3dc2ff" class="reaction-count text-primary">{{ 0 }}</span>
                  </div>
                </ion-button>
              </div>
            </div>
          </ion-card>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center mx-3 mb-2">
      <back-to-top v-if="toTop" class="d-flex align-self-start" />
      <router-link v-if="seeAllLink" :to="seeAllLink" class="ml-auto mr-0 see-all-text"><b>See all</b></router-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { chatboxEllipsesOutline } from 'ionicons/icons';
import BackToTop from '@/shared/components/BackToTop.vue';
import store from '@/shared/store';
import { resizeUpload } from '@/shared/utils/upload';
import Reaction from '@/shared/components/Reaction/index.vue';

defineProps({
  sectionTitle: {
    type: String,
    required: true,
  },
  toTop: {
    type: Boolean,
    default: false,
  },
  storyWorlds: {
    type: Object,
  },
  seeAllLink: {
    type: Object,
  },
});

const reactionChanged = async (reaction: string, wrld: any, isInstant = false) => {
  await store.dispatch('WorldsModule/react', {
    reaction,
    world: wrld,
    isInstant,
  });
};

const openCard = (spaces: any) => {
  const router = useRouter();
  router.push({ name: 'world-details', params: { slug: spaces.slug } });
};
</script>

<style lang="sass" scoped>
.story-worlds
  overflow-x: auto
.name
  font-size: 18px
  font-weight: bold
  cursor: pointer
  word-break: break-word
  margin: auto 0.25rem
  .name:hover
    opacity: 0.7
.see-all-text
  color: #214163
  display: inline-block
.title
  font-size: 24px
  font-weight: bold
  margin-bottom: 12px
  margin-top: 30px
.story-world-card
  overflow: unset
  width: calc(33% - 18px)
  min-width: 200px !important
  text-break: break-word
  border-radius: 10px
  img
    border-top-left-radius: 10px
    border-top-right-radius: 10px

.img
  width: 250px
  height: 130px
  object-fit: cover
.story-world-list
  overflow-x: hidden
  -ms-overflow-style: none
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none
.card-container
  margin: 0 auto
  justify-content: center
@media(max-width: 500px)
  .story-world-card
    width: calc(50% - 18px)
    min-width: 100px !important
  img
    height: auto !important
    aspect-ratio: 1/1
    object-fit: cover
</style>
