<template>
  <div class="wrapper">
    <div class="w-100 pb-4">
      <div class="top-bar d-flex align-items-center px-2">
        <div class="title">{{ sectionTitle }}</div>
      </div>

      <world-card-small
        :vertical="isDesktopSize"
        v-for="world of worlds"
        :key="world.id"
        :world="world"
        class="my-3"
        :member-count="0"
        :world-unread-count="0"
      />
    </div>
    <div class="d-flex align-items-center mx-3 mb-2">
      <back-to-top v-if="toTop" class="d-flex align-self-start" />
      <router-link v-if="seeAllLink" :to="seeAllLink" class="ml-auto mr-0 see-all-text"><b>See all</b></router-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import WorldCardSmall from '@/shared/components/WorldCardSmall.vue';
import BackToTop from '@/shared/components/BackToTop.vue';


const { isDesktopSize } = useWindowSize();

const props = defineProps({
  sectionTitle: {
    type: String,
    required: true,
  },
  toTop: {
    type: Boolean,
    default: false,
  },
  worlds: {
    type: Object,
  },
  seeAllLink: {
    type: Object,
  },
});
</script>

<style lang="sass" scoped>
.story-worlds
  overflow: unset

.title
  font-size: 24px
  font-weight: bold
  margin-bottom: 12px
  margin-top: 30px
.see-all-text
  color: #214163
</style>
