<template>
  <ion-page class="page bg-transparent">
    <div class="d-flex justify-content-between align-items-center flex-wrap py-3">
      <div class="title">Story Worlds</div>

      <div class="ml-auto mx-2">
        <ion-button class="text-white social-btn" @click="createSocialSpace">New Story World</ion-button>
      </div>
    </div>

    <top-banner banner-name="worldsHomeHeader" />

    <div class="top-nav mt-4 px-1 mb-4">
      <div class="top-nav-btn clickable-item-hov" @click="scrollTo('trending')">
        <span>Trending </span>
      </div>
      <div class="top-nav-btn clickable-item-hov" @click="scrollTo('top')">
        <span>Top</span>
      </div>
      <div class="top-nav-btn clickable-item-hov" @click="scrollTo('latest')">
        <span>Latest</span>
      </div>
      <div class="top-nav-btn clickable-item-hov" @click="gotoworlds()">
        <span>My Worlds</span>
      </div>
    </div>

    <top-banner banner-name="storyworldsHomeHeader" class="mt-4" />

    <transition>
      <vertical-story-worlds
        id="trending"
        section-title="Trending Story Worlds"
        :see-all-link="{ name: 'worlds-tabs', query: { tab: 'trending' } }"
        :worlds="trendingWorlds"
        :to-top="true"
      />
    </transition>

    <hr class="mx-5 my-5" />

    <transition>
      <horizontal-story-worlds
        id="top"
        section-title="Top Story Worlds"
        :see-all-link="{ name: 'worlds-tabs', query: { tab: 'top' } }"
        :to-top="true"
        :story-worlds="topWorlds"
      />
    </transition>

    <hr class="mx-5 my-5" />

    <transition>
      <vertical-story-worlds
        id="latest"
        section-title="Latest Story Worlds"
        :see-all-link="{ name: 'worlds-tabs', query: { tab: 'latest' } }"
        :worlds="latestWorlds"
        :to-top="true"
      />
    </transition>
  </ion-page>
</template>

<script lang="ts" setup>
import VerticalStoryWorlds from './VerticalStoryWorlds.vue';
import HorizontalStoryWorlds from './HorizontalStoryWorlds.vue';
import { sendAnalyticsEvent } from '@/shared/services/analytics';
import TopBanner from '@/shared/pages/home/components/TopBanner.vue';
import store from '@/shared/store';

const { isDesktopSize } = useWindowSize();
const { isChromeBrowser } = useBrowserName();

const createSocialSpace = () => {
  const router = useRouter();
  router.push({ name: 'create-story-world' });
};

const trendingWorlds = computed(() => {
  return store.getters['WorldsModule/trendingWorlds'];
});
const topWorlds = computed(() => {
  return store.getters['WorldsModule/topWorlds'];
});
const latestWorlds = computed(() => {
  return store.getters['WorldsModule/latestWorlds'];
});

const gotoworlds = () => {
  const router = useRouter();
  router.push({ name: 'worlds-tabs', query: { tab: 'myworlds' } });
};

const getTrendingWorlds = async () => {
  await store.dispatch('WorldsModule/getTrendingWorlds', {
    type: 'story',
    pageSize: 5,
  });
};
const getTopWorlds = async () => {
  await store.dispatch('WorldsModule/getTopWorlds', {
    type: 'story',
    pageSize: 6,
  });
};
const getLatestWorlds = async () => {
  await store.dispatch('WorldsModule/getLatestWorlds', {
    pageSize: 5,
    type: 'story',
  });
};

const scrollTo = (id: string) => {
  try {
    const document = useDocument();
    isDesktopSize.value && isChromeBrowser.value
      ? document.value?.getElementById(id)?.scrollIntoView({ behavior: 'auto', inline: 'center', block: 'nearest' })
      : document.value?.getElementById(id)?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
  } catch (e) {}
  try {
    sendAnalyticsEvent('Pressed Scroll Shortcut Button on Home', {
      to: id,
    });
  } catch (e) {}
};

onMounted(() => {
  getTrendingWorlds();
  getTopWorlds();
  getLatestWorlds();
});
</script>

<style lang="sass" scoped>
.top-nav
  display: grid
  gap: 15px 6px
  width: calc(100% - 6px) !important
  grid-template-columns: repeat(4, 1fr)
.top-nav-btn
  color: white
  border-radius: 10px
  background-color: #8928C4
  padding: 0.25rem 0.15rem
  height: 35px
  line-height: 25px
  text-align: center
  font-size: 16px
  width: 100%

  span
    user-select: none !important
  @media(max-width: 500px)
    width: 100%
  @media(max-width: 300px)
    width: 81px

.social-btn
  color: #214163
  --border-radius: 10px
  font-size: 14px
  font-weight: bold
  width: 157px
  height: 33px
  text-transform: none
  @media(max-width: 500px)
    width: 145px
  @media(max-width: 300px)
    width: 108px

.title
  font-size: 24px
  font-weight: bold

@media (max-width: 767px)
  .top-nav
    display: grid
    position: relative
    gap: 5px 5px
    max-width: 100%
    margin-top: 12px
    grid-template-columns: repeat(2, 1fr)
</style>
